import React from "react";
import { useState, /* useEffect */ } from "react";
import { Button, /* Dropdown, ButtonGroup  */} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FormConst } from '../config';
import { Collapse } from 'reactstrap';

const NewFormGridUsers = (props) => {

    //console.log("props.forms.forms", props.forms.forms)
    const [collapsed, setCollapsed] = useState({});
    const [showAllForms, setShowAllForms] = useState(false);
    const [dispalyAllButtonText, setdispalyAllButtonText] = useState("Εμφάνιση Όλων");

    const toggleCollapse = (key) => {
        setCollapsed(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const displayAllForms = () => {
        if (showAllForms === true) {
            setShowAllForms(false);
            setdispalyAllButtonText("Εμφάνιση Όλων")
        } else if (showAllForms === false) {
            setShowAllForms(true);
            setdispalyAllButtonText("Εμφάνιση Κατηγοριών")
        }
    }

    const groupedData = (forms) => forms.reduce((accumulator, item) => {
        if (!item.properties.category) {
            item.properties.category = FormConst.FORM_CATEGORY_GENIKES
        }
        if (!accumulator[item.properties.category]) {
            accumulator[item.properties.category] = [];
        }
        accumulator[item.properties.category].push(item);
        return accumulator;
    }, {});

    let groupedForms = groupedData(props.forms.forms)
    //console.log("groupedForms", groupedForms)

    /*  const [pageSize, setPageSize] = useState(10),
         onPageSizeChange = (n) => {
             setPageSize(n)
             setPage(0)
         } */

    //const [numberOfPages, setNumberOfPages] = useState(Math.ceil(props.forms.length / pageSize))

    /*  useEffect(() => {
         setNumberOfPages(Math.ceil(props.forms.length / pageSize))
     }, [props.forms.length, pageSize]); */

    /* const [page, setPage] = useState(0),
        onNextPage = () => {
            setPage((page + 1) % numberOfPages)
        },
        onPrevPage = () => {
            setPage((page - 1))
        },
        onStartPage = () => {
            setPage(0)
        },
        onLastPage = () => {
            setPage(numberOfPages - 1)
        }

    const [displayAll, setDisplayAll] = useState(false),
        clickDisplayAll = () => {
            setDisplayAll(!displayAll)
        }

    const [sortedForms, setSortedForms] = useState(props.forms);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }) */

    /*   const sortData = (key) => {
          let direction = 'ascending'
          if (sortConfig.key === key && sortConfig.direction === 'ascending') {
              direction = 'descending';
          } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
              direction = 'ascending';
          }
  
          let tempForms = sortedForms
          const tempSortedForms = [...tempForms].sort((a, b) => {
              if (key === 'title') {
                  if (a[key] < b[key]) {
                      return direction === 'ascending' ? -1 : 1;
                  }
                  if (a[key] > b[key]) {
                      return direction === 'ascending' ? 1 : -1;
                  }
              } else if (key === 'status') {
                  if (a.properties[key] < b.properties[key]) {
                      return direction === 'ascending' ? -1 : 1;
                  }
                  if (a.properties[key] > b.properties[key]) {
                      return direction === 'ascending' ? 1 : -1;
                  }
                  // If values of key are equal, case of 'published', sort by 'endDate'
                  const date1 = new Date(Date.now())
                  const date2 = new Date(a.properties["endDate"])
                  if (date1 < date2) {
                      return direction === 'ascending' ? -1 : 1;
                  }
                  if (date1 > date2) {
                      return direction === 'ascending' ? 1 : -1;
                  }
                  return 0;
              }
              else {
                  if (a.properties[key] < b.properties[key]) {
                      return direction === 'ascending' ? -1 : 1;
                  }
                  if (a.properties[key] > b.properties[key]) {
                      return direction === 'ascending' ? 1 : -1;
                  }
              }
              return 0;
          });
          setSortedForms(tempSortedForms);
          setSortConfig({ key, direction });
      }; */

    //console.log("collapsed", collapsed)
    return (
        <div style={{ marginBottom: "1.3rem" }}>
            {props.forms.forms.length ?
                <>
                    <div className="text-right" style={{ marginBottom: "1.1rem" }}>
                        <Button variant="success" size="sm" onClick={() => displayAllForms()}>
                            {dispalyAllButtonText}
                        </Button>
                    </div>
                    <div>
                        {showAllForms ?
                            <>
                                <ul className="list-group">
                                    <li className="list-group-item hidden-xs hidden-md">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span style={{ marginLeft: "5px" }}>Φόρμα</span>
                                            <span>Λειτουργίες</span>
                                        </div>
                                    </li>
                                    {props.forms.forms.map(item => (
                                        <li key={item._id} className="list-group-item">
                                            <Link to={`/form/${item._id}/userSubmissions`}
                                                className="d-flex justify-content-between align-items-center"
                                                style={{ textDecoration: "none", fontWeight: "bold" }}>
                                                <span style={{ marginLeft: "5px" }}>{item.title}</span>
                                                <span title="Υποβολή" role="button" tabindex="0" className="btn btn-primary btn-sm">
                                                    <span>
                                                        <i className="fa fa-pencil"></i>&nbsp;
                                                    </span>
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </> :
                            <>
                                <ul className="list-group">
                                    <li className="list-group-item hidden-xs hidden-md">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span style={{ marginLeft: "5px" }}>Φόρμα</span>
                                            <span>Λειτουργίες</span>
                                        </div>
                                    </li>

                                    {Object.keys(groupedForms).map(categoryKey => (
                                        <li key={categoryKey} className="list-group-item hidden-xs hidden-md">
                                            <Button color="primary" variant="outline-primary" size="sm" onClick={() => toggleCollapse(categoryKey)}>
                                                {categoryKey}
                                            </Button>
                                            <Collapse isOpen={collapsed[categoryKey]}>
                                                {groupedForms[categoryKey].map(item => (
                                                    <li key={item._id} className="list-group-item">
                                                        <Link to={`/form/${item._id}/userSubmissions`}
                                                            className="d-flex justify-content-between align-items-center"
                                                            style={{ textDecoration: "none", fontWeight: "bold" }}>
                                                            <span style={{ marginLeft: "5px" }}>{item.title}</span>
                                                            <span title="Υποβολή" role="button" tabindex="0" className="btn btn-primary btn-sm">
                                                                <span>
                                                                    <i className="fa fa-pencil"></i>&nbsp;
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </Collapse>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        }
                    </div>

                    {/*  {props.forms.forms.map(item => (
                    <li key={item.id} className="list-group-item hidden-xs hidden-md">
                        <Link to={`/form/${item._id}/userSubmissions`} className="d-flex justify-content-between align-items-center">
                            <span style={{ marginLeft: "5px" }}>{item.name}</span>
                            <span title="Υποβολή" role="button" tabindex="0" className="btn btn-primary btn-sm">
                                <span>
                                    <i className="fa fa-pencil"></i>&nbsp;
                                </span>
                            </span>
                        </Link>
                    </li> */}


                </>
                : <p>Καμία φόρμα</p>}
            {/*  <table style={tableStyles.table}>
                <thead style={tableStyles.thead}>
                    <tr title="Κάνετε κλικ για ταξινόμηση">
                        <th style={{ width: "40%" }} onClick={() => sortData('title')}>Τίτλος</th>
                        <th onClick={() => sortData('customOwnerEmail')}>Email</th>
                        <th onClick={() => sortData('customOwnerSelectedAdminRole')}>Δημιουργός</th>
                        <th onClick={() => sortData('status')}>Κατάσταση</th>
                        <th onClick={() => sortData('category')}>Κατηγορία</th>
                        <th style={{ textAlign: "right" }}>Λειτουργίες</th>
                    </tr>
                </thead>
                <tbody style={tableStyles.tbody}>
                    {!displayAll ? sortedForms.slice(page * pageSize, (page + 1) * pageSize).map((el, k) =>
                        <NewFormGridItem key={el._id}
                            formID={el._id}
                            form={el}

                        />) : sortedForms.map((el, k) =>
                            <NewFormGridItem key={el._id}
                                formID={el._id}
                                form={el}

                            />)
                    }
                </tbody>
            </table> */}
            {/*  <div style={{ marginTop: "1.3rem" }}>
                {!displayAll ?
                    <>
                        <Button variant="outline-primary" onClick={onStartPage} disabled={!page}><i style={{ pointerEvents: "none" }} class="fa fa-step-backward" aria-hidden="true"></i></Button>
                        <Button variant="outline-primary" onClick={onPrevPage} disabled={!page}>Προηγούμενο</Button>
                        <Button variant="outline-primary" onClick={onNextPage} disabled={page === Math.ceil(sortedForms.length / pageSize) - 1 || sortedForms.length === 0}>Επόμενο</Button>
                        <Button variant="outline-primary" onClick={onLastPage} disabled={page === numberOfPages - 1 || sortedForms.length === 0}><i style={{ pointerEvents: "none" }} class="fa fa-step-forward" aria-hidden="true"></i></Button>
                        <Dropdown style={{ marginLeft: "1.3rem" }} as={ButtonGroup}>
                            <Button variant="outline-primary">N</Button>
                            <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" />
                            <Dropdown.Menu>
                                <Dropdown.Item style={pageSize === 10 ? { color: "white", backgroundColor: "#43B6D7" } : {}} onClick={() => onPageSizeChange(10)}>10</Dropdown.Item>
                                <Dropdown.Item style={pageSize === 20 ? { color: "white", backgroundColor: "#43B6D7" } : {}} disabled={props.forms.length < 10} onClick={() => onPageSizeChange(20)}>20</Dropdown.Item>
                                <Dropdown.Item style={pageSize === 30 ? { color: "white", backgroundColor: "#43B6D7" } : {}} disabled={props.forms.length < 20} onClick={() => onPageSizeChange(30)}>30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <span class="alert alert-primary" style={{ marginLeft: "1.3rem" }}>Σελίδα {page + 1} Από {numberOfPages === 0 ? numberOfPages + 1 : numberOfPages}</span>
                    </> : <></>}
                {props.forms.length < 10 ? <></>
                    : <Button variant="info" onClick={clickDisplayAll} style={{ marginLeft: "1.3rem" }}> {!displayAll ? "Εμφάνιση όλων" : "Εμφάνιση Σελίδων"}</Button>
                }
            </div> */}
        </div>
    )
}

export { NewFormGridUsers }